<mat-dialog-content *ngIf="!isLoading">
    <div class="container">
      <div class="dialog-header-container">
        <div class="dialog-header-left-side-elements">
          <h2>Diagnosis Codes</h2>
        </div>
        <div class="dialog-header-right-side-elements">
        </div>
      </div>
      <div class="content-wrapper">

        <mat-card *ngFor="let code of codeGroups" class="data-entry-card">
          <mat-card-header>
            <mat-card-title>{{code.label}} DX Code
            </mat-card-title>
            <div class="right-side-card-elements">
              <button class="add" mat-flat-button (click)="onAddDiagnosisCodeLine(code.value)" >
                <mat-icon>add_circle</mat-icon>
                New
              </button>
            </div>
          </mat-card-header>

            <div class="mat-card-divider"></div>

            <mat-card-content>
              <div class="form-container alt-theme">
                <form [formGroup]="formGroup">
                  <ng-container formArrayName="diagnosisCodes">
                    <ng-container *ngFor="let dcRow of diagnosisCodes.controls; let i = index" class="dynamic-row">
                      <div *ngIf="dcRow.value?.type === code.value" class="lesson-form-row" [formGroup]="getDiagnosisCodeFormGroup(i)" [ngStyle]="getRowDisplayStyle(i)" class="form-group">
                        <mat-form-field class="code" appearance="outline" color="primary">
                          <mat-label>Diagnosis Code</mat-label>
                          <input
                            [attr.data-code-type]="code.value"
                            #diagnosisCodeInput
                            matInput formControlName="diagnosisCode" placeholder="Diagnosis Code" maxlength="8" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline" color="primary" class="description" [matTooltip]="getDiagnosisCodeFormGroup(i).controls['description'].value">
                          <mat-label>Description</mat-label>
                          <input matInput formControlName="description" placeholder="Description">
                        </mat-form-field>
                        <mat-form-field class="type" appearance="outline" color="primary" disabled="true">
                          <mat-label>Type</mat-label>
                          <mat-select formControlName="type" name="type" (selectionChange)="onTypeChange($event, code.value, i)">
                            <mat-option *ngFor="let option of code.dropdownOptions" [value]="option.value">{{option.label}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="poa-value">
                          <mat-label>POA</mat-label>
                          <mat-select formControlName="presentOnAdmission">
                            <mat-option *ngFor="let t of poaValues" [value]="t.value">
                              {{ t.label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <button mat-flat-button (click)="onDelete(i)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>
                </form>
              </div>
              <!-- <div class="counter-div">
                <span class="counter-stats">{{code.currentValue}} of {{code.maxValue}}</span>
              </div> -->
            </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
    <mat-checkbox class="mark-complete-checkbox" [checked]="statusTrackComplete" (change)="toggleStatusTrackComplete()">
      Mark Complete
    </mat-checkbox>
    <button mat-flat-button color="accent" (click)="onSubmit()" [disabled]="!formGroup?.valid">
      <mat-icon>save</mat-icon> Save
    </button>
    <button mat-flat-button color="primary" (click)="onCancel()">
      <mat-icon>cancel</mat-icon> Cancel
    </button>
  </mat-dialog-actions>


