import { EnvironmentI } from 'xf-common';

export const environmentValue: EnvironmentI = {
  app: "web",
  production: true,
  baseApiUrl: "https://api-web.dev.xorfox.cloud/api/",
  baseApiAttachmentUrl: "https://api-web-attachment.dev.xorfox.cloud/api/",
  keycloakServerUrl: "https://keycloak.dev.xorfox.cloud/",
  keycloakRealmName: "FennecWebRealm",
  keycloakClientId: "fennec-web-login",
  keycloakRedirectUri: "https://web.dev.xorfox.cloud/*",
};
