import {Component, ElementRef, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { CodeValidationService } from '../../code/code-validation.service';
import { FennecSnackbarService } from '../../dialog/fennec-snackbar/fennec-snackbar.service';
import { Ub04DiagnosisCodeBulkUpdatePacket } from '../../model/net/ub04-diagnosis-code-bulk-update-packet';
import { StatusTrackService } from '../../status-track/status-track.service';
import {
  UB04StatusTrackBaseDialogComponent
} from "../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component";
import { Ub04Service } from "../ub04.service";
import { Ub04DiagnosisCodeUpdatePacket } from "../../model/net/ub04-diagnosis-code-update-packet";
import { BaseResponse } from "../../model/base-response";
import { Logger } from '../../util/logger';

@Component({
  selector: 'app-ub04-diagnosis-code-dialog',
  templateUrl: './ub04-diagnosis-code-dialog.component.html',
  styleUrls: ['./ub04-diagnosis-code-dialog.component.scss']
})
export class Ub04DiagnosisCodeDialogComponent extends UB04StatusTrackBaseDialogComponent implements OnInit {
  override log = new Logger("Ub04DiagnosisCodeDialogComponent");
  codeGroups = [
      {
        label: "Primary",
        value: "PRIMARY",
        dropdownOptions: [
          {
            label: "Primary",
            value: "PRIMARY"
          },
        ],
        maxValue: 1,
        currentValue: 0
      },
      {
        label: "Admitting",
        value: "ADMITTING",
        dropdownOptions: [
          {
            label: "Admitting",
            value: "ADMITTING"
          },
        ],
        maxValue: 1,
        currentValue: 0
      },
      {
        label: "Patient Reason",
        value: "PATIENT_REASON",
        dropdownOptions: [
          {
            label: "Patient Reason",
            value: "PATIENT_REASON"
          },
          {
            label: "External Cause of Injury",
            value: "EXTERNAL_CAUSE_OF_INJURY"
          },
          {
            label: "Other",
            value: "OTHER"
          },
        ],
        maxValue: 3,
        currentValue: 0
      },
      {
        label: "External Cause of Injury",
        value: "EXTERNAL_CAUSE_OF_INJURY",
        dropdownOptions: [
          {
            label: "Patient Reason",
            value: "PATIENT_REASON"
          },
          {
            label: "External Cause of Injury",
            value: "EXTERNAL_CAUSE_OF_INJURY"
          },
          {
            label: "Other",
            value: "OTHER"
          },
        ],
        maxValue: 4,
        currentValue: 0
      },
      {
        label: "Other",
        value: "OTHER",
        dropdownOptions: [
          {
            label: "Patient Reason",
            value: "PATIENT_REASON"
          },
          {
            label: "External Cause of Injury",
            value: "EXTERNAL_CAUSE_OF_INJURY"
          },
          {
            label: "Other",
            value: "OTHER"
          },
        ],
        maxValue: 17,
        currentValue: 0
      }
  ];
  primaryCodeCount: number = 0;
  patientCodeCount: number = 0;
  otherCodeCount: number = 0;
  externalCodeCount: number = 0;
  admittingCodeCount: number = 0;
  formGroup!: FormGroup;
  ub04DiagnosisCodes: any [] = [];
  diagnosisCodeTypes: any;
  poaValues: any = [];
  @ViewChildren('diagnosisCodeInput') diagnosisCodeInputs: QueryList<ElementRef<HTMLInputElement>>;

  constructor(
    private codeValidationService: CodeValidationService,
    public dialogRef: MatDialogRef<Ub04DiagnosisCodeDialogComponent>,
    private statusTrackService: StatusTrackService,
    override snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT_DIAGNOSIS_CODE, statusTrackService);
    setTimeout(() => {
      this.formGroup = new FormGroup({
        diagnosisCodes: new FormArray([])
      });
    });
    this.getDiagnosisCodeTypes();
    this.getPOAValues();
  }

  ngOnInit(): void {
    this.fetchData();
  }

  getDiagnosisCodeTypes() {
    this.ub04Service.getUB04DiagnosisCodeTypes().subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.diagnosisCodeTypes = response.data;
        }
      }
    )
  }

  getRowDisplayStyle(idx: number): any {
    let rcFormGroup: FormGroup = this.getDiagnosisCodeFormGroup(idx);
    if (rcFormGroup.controls["deleteRow"].value) {
      return {
        "background-color": "salmon"
      };
    } else {
      return {};
    }
  }

  fetchData() {
    this.loading$.next(true);
    this.ub04Service.getUb04DiagnosisCodes(this.data.ub04Id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ub04DiagnosisCodes = response.data;
        this.populateDiagnosisCodesOnFormGroup();
      }
      this.loading$.next(false);
    });
  }

  onTypeChange(event: any, source: string, index: number) {
    let limitExceeded = false;
    let rc: FormGroup = this.getDiagnosisCodeFormGroup(index);
    switch (event.value) {
      case "PATIENT_REASON":
        if (this.codeGroups[2].currentValue >= this.codeGroups[2].maxValue) {
          limitExceeded = true;
        }
        break;
      case "EXTERNAL_CAUSE_OF_INJURY":
        if (this.codeGroups[3].currentValue >= this.codeGroups[3].maxValue) {
          limitExceeded = true;
        }
        break;
      case "OTHER":
        if (this.codeGroups[4].currentValue >= this.codeGroups[4].maxValue) {
          limitExceeded = true;
        }
        break;
    }
    if (!limitExceeded) {
      this.calculateCodeTypeLengths(event.value, true);
      this.calculateCodeTypeLengths(source, false);
    } else {
      rc.controls["type"].setValue(source);
      this.snack.showErrorSnack(`Unable to reassign to ${event.value}: category max reached, changing type back to ${source}`);
    }
  }

  populateDiagnosisCodesOnFormGroup() {
    // If this is the first time entering revenue codes, pop user in with 3 blank lines
    // to start with.
    if (this.ub04DiagnosisCodes.length <= 0) {
        this.onAddDiagnosisCodeLine("PRIMARY");
        this.onAddDiagnosisCodeLine("ADMITTING");
        this.loading$.next(false);
        return;
    }

    // Prepopulate from existing ub04 diagnosis list.
    let diganosisCodes = this.diagnosisCodes;
    diganosisCodes.clear();
    let idx = 0;
    let admittingDXCodeFound = false;
    for (idx = 0; idx < this.ub04DiagnosisCodes.length; idx++) {
      let dc = this.ub04DiagnosisCodes[idx];
      let dcFormGroup: FormGroup = new FormGroup({
        id: new FormControl(dc.id),
        diagnosisCode: new FormControl(dc.diagnosisCode, Validators.required),
        description: new FormControl(dc.diagnosisCodeDescription),
        type: new FormControl(dc.ub04DiagnosisCodeType),
        tempKey: new FormControl(this.codeValidationService.createUUID()),
        deleteRow: new FormControl(false),
        presentOnAdmission: new FormControl(dc.presentOnAdmission)
      });
      dcFormGroup.controls['description'].disable();
      // Chatty revenue code validation during data entry
      dcFormGroup.controls['diagnosisCode'].valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe((val) => {
        if (dcFormGroup.controls["diagnosisCode"].value.length >= 3) {
          this.validateDiagnosisCode(dcFormGroup, val);
        }
      });
      if (dcFormGroup.controls['type'].value === 'ADMITTING') {
        admittingDXCodeFound = true;
      }
      diganosisCodes.push(dcFormGroup);
    }
    if (!admittingDXCodeFound) {
      this.onAddDiagnosisCodeLine("ADMITTING");
    }
    this.organizeCodesByType();
    this.loading$.next(false);
  }

  onAddDiagnosisCodeLine(source?: string) {
    let diagnosisCodes = this.diagnosisCodes;
    let dcFormGroup: FormGroup = new FormGroup({
      id: new FormControl(-1),
      diagnosisCode: new FormControl("", Validators.required),
      description: new FormControl(),
      type: new FormControl(source ? source : null),
      tempKey: new FormControl(this.codeValidationService.createUUID()),
      deleteRow: new FormControl(false),
      presentOnAdmission: new FormControl()
    });
    dcFormGroup.controls['description'].disable();
    dcFormGroup.controls['diagnosisCode'].valueChanges.subscribe((val) => {
      if (dcFormGroup.controls["diagnosisCode"].value.length >= 3) {
        this.validateDiagnosisCode(dcFormGroup, val);
      }
    });
    diagnosisCodes.push(dcFormGroup);
    this.calculateCodeTypeLengths(source, true);
    setTimeout(() => {
      this.diagnosisCodeInputs
        ?.filter(input => input?.nativeElement?.dataset['codeType'] === source)
        ?.at(-1)
        ?.nativeElement
        ?.focus();
    }, 20);
  }

  calculateCodeTypeLengths(source?: string, addition?: boolean) {
    switch (source) {
      case "PRIMARY":
        addition ? this.primaryCodeCount += 1 : this.primaryCodeCount -= 1;
        this.codeGroups[0].currentValue = this.primaryCodeCount;
        return;
      case "ADMITTING":
        addition ? this.admittingCodeCount += 1 : this.admittingCodeCount -= 1;
        this.codeGroups[1].currentValue = this.admittingCodeCount;
        return;
      case "PATIENT_REASON":
        addition ? this.patientCodeCount += 1 : this.patientCodeCount -= 1;
        this.codeGroups[2].currentValue = this.patientCodeCount;
        return;
      case "EXTERNAL_CAUSE_OF_INJURY":
        addition ? this.externalCodeCount += 1 : this.externalCodeCount -= 1;
        this.codeGroups[3].currentValue = this.externalCodeCount;
        return;
      case "OTHER":
        addition ? this.otherCodeCount += 1 : this.otherCodeCount -= 1;
        this.codeGroups[4].currentValue = this.otherCodeCount;
        return;
      case "":
        return;
    }
  }

  validateDiagnosisCode(row: FormGroup, diagnosisCodeRow: any) {
    let dcFormControl: FormControl = row.controls['diagnosisCode'] as FormControl;
    let descriptionFormControl: FormControl = row.controls['description'] as FormControl;
    if (!dcFormControl.pristine) {
      this.codeValidationService.getDiagnosisCode(dcFormControl.value).subscribe((response: BaseResponse) => {
        if (response.hasErrors) {
          dcFormControl.markAsPristine();
          dcFormControl.setErrors({'incorrect': true});
        } else {
          if (response.data !== null) {
            dcFormControl.markAsPristine();
            descriptionFormControl.setValue(response.data.description, {onlySelf: true, emitEvent: false});
            dcFormControl.setErrors(null);
          } else {
            descriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
            dcFormControl.markAsPristine();
            dcFormControl.setErrors({'incorrect': true});
          }
        }
      });
    }
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    let updDto: Ub04DiagnosisCodeBulkUpdatePacket = new Ub04DiagnosisCodeBulkUpdatePacket();
    let rcRows: FormArray = this.diagnosisCodes;
    rcRows.controls.forEach((rcRow: any) => {
      let diagnosisCode = rcRow.controls["diagnosisCode"].value;
      if (diagnosisCode !== null && diagnosisCode !== undefined) {
        let dcDto: Ub04DiagnosisCodeUpdatePacket = new Ub04DiagnosisCodeUpdatePacket();
        dcDto.id = rcRow.controls["id"].value;
        dcDto.ub04Id = this.data.ub04Id;
        dcDto.diagnosisCodeId = -1;
        dcDto.diagnosisCodeString = rcRow.controls["diagnosisCode"].value;
        dcDto.deleteRow = rcRow.controls["deleteRow"].value;
        dcDto.ub04DiagnosisCodeType = rcRow.controls["type"].value;
        dcDto.presentOnAdmission = rcRow.controls["presentOnAdmission"].value;
        updDto.ub04DiagnosisCodes.push(dcDto);
      }
    });

    // this.ub04Service.putUb04DiagnosisCodesBulkUpdate(updDto).subscribe(response => {
    //   if (response.hasErrors) {
    //     this.snack.showErrorSnack(response.consolidatedErrorMessage);
    //   } else {
    //     const returnObj = {
    //       confirm: true,
    //     }
    //     this.dialogRef.close(returnObj);
    //     this.snack.showSuccessSnack(`Successfully Updated!`);
    //   }
    // });

    this.performXFRequest({
      requestDescription: "BULK UPDATE UB04 Diagnosis Code",
      requestFn: this.ub04Service.putUb04DiagnosisCodesBulkUpdate,
      fnParams: [updDto],
      onSuccess: data => {
        // super.showSuccessSnack("Successful!");
        // this.executeListQuery();
        const returnObj = {
            confirm: true,
          }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Updated!`);
      },
      onError: errString => {
        this.snack.showErrorSnack(errString);
      }
    })
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  onDelete(idx: number) {
    let rfg: FormGroup = this.getDiagnosisCodeFormGroup(idx);
    // These are newly added by user during this session. Just remove them from the
    // form group list.
    if (rfg.controls["id"].value < 0) {
      this.diagnosisCodes.removeAt(idx);
      this.calculateCodeTypeLengths(rfg.controls["type"].value, false)
    } else {
      rfg.controls["deleteRow"].setValue(!rfg.controls["deleteRow"].value);
    }
  }

  findControlUsingTempKey(tempKey: string): FormGroup {
    let ctrl: FormGroup = new FormGroup({});
    let idx = 0;
    for (idx = 0; idx < this.diagnosisCodes.length; idx++) {
      let rc: FormGroup = this.getDiagnosisCodeFormGroup(idx);
      if (rc.controls['tempKey'].value === tempKey) {
        ctrl = rc;
        break;
      }
    }
    return ctrl;
  }

  organizeCodesByType() {
    let rcRows: FormArray = this.diagnosisCodes;
    rcRows.controls.forEach((rcRow: any) => {
      let type = rcRow.controls["type"].value;
      switch (type) {
        case "PRIMARY":
          this.primaryCodeCount += 1;
          this.codeGroups[0].currentValue = this.primaryCodeCount;
          return;
        case "ADMITTING":
          this.admittingCodeCount += 1;
          this.codeGroups[1].currentValue = this.admittingCodeCount;
          return;
        case "PATIENT_REASON":
          this.patientCodeCount += 1;
          this.codeGroups[2].currentValue = this.patientCodeCount;
          return;
        case "EXTERNAL_CAUSE_OF_INJURY":
          this.externalCodeCount += 1;
          this.codeGroups[3].currentValue = this.externalCodeCount;
          return;
        case "OTHER":
          this.otherCodeCount += 1;
          this.codeGroups[4].currentValue = this.otherCodeCount;
          return;
      }
    });
  }

  get diagnosisCodes(): FormArray {
    return this.formGroup.controls["diagnosisCodes"] as FormArray;
  }

  getDiagnosisCodeFormGroup(idx: number): FormGroup {
    return this.diagnosisCodes.at(idx) as FormGroup;
  }

  getPOAValues() {
    this.ub04Service.getAllPOAValues().subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.poaValues = response.data;
      }
    });
  }
}
